<template>
	<div class="projectplan-style">
		<headerNav title="预算单" leftNav="返回" @leftClick="back"></headerNav>
		<div class="content-style">
			<!-- <pullRefresh ref="pullRefresh" @refresh="onRefresh"> -->
				<!-- 加载中 -->
				<div style="height: calc(100vh - 55px);display: flex;align-items: center;justify-content: center;" v-if="loading">
					<van-loading size="24px" vertical>加载中，请稍后...</van-loading>
				</div>
				<div class="pullRefresh-style" v-else>
					<!-- 自定义暂无数据 -->
					<div class="empty" v-if="!list.length">
						<img src="../../../../assets/uf23.png" alt="" style="width: 100%;">
						<div class="empty-text">暂无内容</div>
					</div>
					<!-- table -->
					<el-table :data="list" style="width: 100%;" height="calc(100vh - 88px)" row-class-name="market-row" row-key="id"
						:tree-props="{ children: 'subset' }" @cell-click="cellClick" @row-click="rowClick"
						@expand-change="expandChange" :header-cell-style="{ background: '#f2f2f2', height: '48px' }"
						:row-style="{ height: '48px' }"	ref="elTable" v-else>
						<el-table-column prop="parentName" label="项目名称">
							<template slot-scope="scope">
								<div v-if="scope.row.parentId === '-1'">
									<img src="../../../../assets/uf24.png" alt="" style="width: 12px;">&nbsp;
									<span style="color: #FF8450;">{{ scope.row.parentName }}</span>
								</div>
								<div v-else class="van-ellipsis" :class="scope.row.isCancel == '1'?'cancel_color':''" style="width: 100%;max-width: calc(100vw - 284px);color: #0084F4;">
									{{ scope.row.propertyName }}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="standardQuantity" label="标配工程量" align="right" width="110">
							<template slot-scope="scope">
								<span v-if="scope.row.parentId !== '-1'" :class="scope.row.isCancel == '1'?'cancel_color':''" style="width: 100%;text-align: right;">
									{{ scope.row.standardQuantity }}
								</span>
							</template>
						</el-table-column>
						<el-table-column prop="addQuantity" label="增减工程量" align="right" width="110">
							<template slot-scope="scope">
								<span v-if="scope.row.parentId !== '-1'" :class="scope.row.isCancel == '1'?'cancel_color':''" style="width: 100%;text-align: right;">
									{{ scope.row.addQuantity }}
								</span>
							</template>
						</el-table-column>
					</el-table>
				</div>
			<!-- </pullRefresh> -->
		</div>

		<van-dialog v-model="show" :showConfirmButton="false">
			<template #title>
				<div style="padding: 12px 0;border-bottom: 1px solid #eee;">定额详情</div>
				<div class="dialog-close" @click="dialogClose">
					<img src="@/assets/close.png" alt="" width="100%">
				</div>
			</template>
			<div class="dialog-style">
				<div class="dialog-row">
					<div class="dialog-lab">项目名称：</div>
					<div class="dialog-val">{{ dialogData.propertyName }}</div>
				</div>
				<div class="dialog-row">
					<div class="dialog-lab">项目编码：</div>
					<div class="dialog-val">{{ dialogData.quotaNo }}</div>
				</div>
				<div class="dialog-row">
					<div class="dialog-lab">单位：</div>
					<div class="dialog-val">{{ dialogData.unitName }}</div>
				</div>
				<div class="dialog-row">
					<div class="dialog-lab">客户价（元）：</div>
					<div class="dialog-val">{{ dialogData.sellPrice }}</div>
				</div>
				<div class="dialog-row">
					<div class="dialog-lab">说明：</div>
					<div class="dialog-val">{{ dialogData.remark }}</div>
				</div>
			</div>
		</van-dialog>

		<upBtn />
	</div>
</template>

<script>
import api from '@/api/api.js'
import upBtn from './upBtn.vue'
export default {
	components: {
		upBtn
	},
	data() {
		return {
			list: [],
			info: {},
			typeInfo: {},
			expandList: [],
			show: false,
			dialogData: {},
			loading: false,
		}
	},
	methods: {
		back() {
			this.$router.push({ name: "deepenClicntDetailName", })
		},
		//获取列表
		async getList() {
			try {
				this.loading = true
				const { data } = await api.getBudgetSheetList(this.typeInfo.budgetInfoId)
				this.list = data ?? []
			} finally {
				this.loading = false
			}
		},
		cellClick(row, column) {
			if (column.label === '项目名称' && row.parentId !== '-1' && row.propertyName?.length) {
				this.dialogData = row
				this.show = true
			} else {
				this.dialogData = {}
			}
		},
		rowClick(row) {
			if (row.parentId === '-1' && row.subset?.length) {
				const flag = this.expandList.includes(row.id)
				if (flag) {
					this.$refs.elTable.toggleRowExpansion(row, false);
					this.expandList = this.expandList.filter(item => item !== row.id)
				} else {
					this.$refs.elTable.toggleRowExpansion(row, true);
					this.expandList.push(row.id)
				}
			}
		},
		expandChange(row, expanded) {
			if (expanded) {
				this.expandList = Array.from(new Set([...this.expandList, row.id]))
			} else {
				this.expandList = this.expandList.filter(item => item !== row.id)
			}
		},
		dialogClose() {
			const aa = document.querySelector('.dialog-style')
			aa?.scrollTo({ top: '0', behavior: 'smooth' })
			this.show = false
		},
		onRefresh() {
			this.list = [];
			this.getList();
		},
	},
	mounted() {
		this.info = JSON.parse(sessionStorage.getItem('clientInfo'))
		this.typeInfo = JSON.parse(sessionStorage.getItem('clientTypeInfo'))
		this.getList();
	},
}
</script>

<style scoped lang="scss">
.cancel_color{
	color: #dddddd !important;
}
.projectplan-style {
	.content-style {
		// height: calc(100vh - 50px);
		overflow: auto;

		.pullRefresh-style {
			// min-height: calc(100vh - 66px);
		}

		.empty {
			min-height: calc(100vh - 90px);
			background: #fff;
			padding-top: 40px;

			.empty-text {
				text-align: center;
				font-size: 18px;
				font-family: PingFang SC, PingFang SC;
				color: #5E5E5E;
				margin-top: 10px;
				height: 100%;
			}
		}

		.table {
			font-size: 14px;
		}
	}

	::v-deep .van-dialog__header {
		padding-top: 0;
	}

	::v-deep .van-dialog__content {
		padding: 8px 0;
	}

	::v-deep .cell {
		display: flex;
	}

	::v-deep .van-dialog {
		border-radius: 8px;
	}

	.dialog-close {
		width: 30px;
		height: 30px;
		position: absolute;
		top: 8px;
		right: 16px;
	}

	.dialog-style {
		font-size: 16px;
		padding: 0 16px;
		text-align: left;
		max-height: 50vh;
		overflow-y: auto;


		.dialog-row {
			margin-bottom: 16px;
			display: flex;

			.dialog-lab {
				color: #888;
			}

			.dialog-val {
				flex: 1;
				word-wrap: break-word;
				word-break: break-all;
			}
		}
	}
}
</style>
